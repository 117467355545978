<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
      <template v-slot:divider>
        <v-icon class="secondary--text text--lighten-2"
        >fas fa-caret-right</v-icon
        >
      </template>
		</v-breadcrumbs>
		<h1 class="nutriyou-h1">{{ $t("pageTitle") }}</h1>
		<v-toolbar>
			<v-container fluid>
				<v-row dense>
					<v-col cols="4">
						<v-select :items="filterPeriodItems" v-model="filterSelectedPeriod" @change="LoadStats">
						</v-select>
					</v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Inizio" v-model="filterCustomStartDate" @input="LoadStats"></DatePicker></v-col>
					<v-col cols="4" v-if="filterSelectedPeriod === 'CUSTOM'"><DatePicker label="Data Fine" v-model="filterCustomEndDate" @input="LoadStats"></DatePicker></v-col>
				</v-row>
			</v-container>
		</v-toolbar>
		<v-skeleton-loader v-if="LoadingData"></v-skeleton-loader>
		<template v-else>
			<v-card class="elevate-5" v-if="PeriodStats">
				<!-- <v-card-text>
					: <br />
					Questionnari completati e mail verificati     <br />
					Total incontri conoscitivi  <br />
					Total visite  <br />
					Total piano personalizzato <br />
				</v-card-text> -->
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">
								Descrizione
								</th>
								<th class="text-left">
								Stats
								</th>
							</tr>
						</thead>
						<tbody>
							<tr	>
								<td>Questionari completati</td>
								<td> {{ PeriodStats.TotalTallyRequestsCount }}</td>
							</tr>
							<tr	>
								<td>Questionari Verificati</td>
								<td>{{ PeriodStats.VerifiedTallyRequestsCount }}</td>
							</tr>
							<tr	v-for="item in PeriodStats.BookingStats" :key="item.BookingTypeId + '-' + item.BookingStatusId">
								<td >{{ $t("nutriyou.BookingTypes." + item.BookingTypeId) }} /
										{{ $t("nutriyou.BookingStatus." + item.BookingStatusId) }}
									</td>
									<td >{{ item.Count }}</td>

							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card>
		</template>
	</div>
</template>
<script>
import CrudClient from "@/services/CrudClient/";
import DatePicker from "@/components/Shared/FormControl/DatePicker.vue";
import { mapActions } from "vuex";

export default {

	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageTitle") }],
		};
	},

	components: { DatePicker },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Area Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
				{
					text: "Dashboard",
					disabled: false,
					exact: true,
					to: "/Admin/Dashboard",
				}
			],
			LoadingData: false,
			PeriodStats: null,
			filterSelectedPeriod: null,
			filterPeriodItems: [{
				text: "Marzo 2023",
				value: "2023/03"
			}, {
				text: "Aprile 2023",
				value: "2023/04"
			}, {
				text: "Maggio 2023",
				value: "2023/05"
			},
			{
				text: "Giugno 2023",
				value: "2023/06"
			},
			{ divider: true },
			{
				text: "Personalizzato",
				value: "CUSTOM"
			},
			],
			filterCustomStartDate: null,
			filterCustomEndDate: null
		};
	},
	created() {
		this.ReportsService = new CrudClient("Reports/PeriodStats");
	},

	async mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/Admin/Dashboard/MonthlySummary",
		},);

		await this.LoadStats();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		getIntervalDates() {
			if (!this.filterSelectedPeriod) {
				return null;
			}

			if (this.filterSelectedPeriod === "CUSTOM") {
				if (this.filterCustomStartDate && this.filterCustomEndDate) {
					return {
						startDate: this.filterCustomStartDate,
						endDate: this.filterCustomEndDate
					}
				}

				return null;
			}
			const period = this.filterSelectedPeriod.split("/");

			const year = parseInt(period[0]);
			const month = parseInt(period[1]);

			let endYear = year;
			let endMonth = month + 1;
			if (endMonth > 12) {
				endMonth = 1;
				endYear++;
			}

			return {
				startDate: period[0] + "-" + period[1] + "-1",
				endDate: endYear + "-" + endMonth + "-1",
			}
		},
		async LoadStats() {
			try {
				this.LoadingData = true;
				const interval = this.getIntervalDates();
				if (!interval) { return; }

				this.$log.debug(interval);
				this.PeriodStats = await this.ReportsService.Get(`Stats?startDate=${interval.startDate}&endDate=${interval.endDate}`);
				this.$log.debug(this.PeriodStats);
			} catch (error) {
				this.snackError({ Text: this.$t("common.error.cannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		}
	}
};
</script>
<i18n>
{
	"it":{
		"pageTitle": "Report Mensile"
	}
}
</i18n>
